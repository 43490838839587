import React, { useEffect } from 'react';

declare global {
  interface Window {
    hbspt: any;
  }
}

interface HubspotMeetingProps {
  src: string;
  className: string;
  id: string | number;
}

export const HubspotMeeting = ({ src, className, id }: HubspotMeetingProps): JSX.Element => {
  useEffect(() => {
    if (window && document) {
      const script = document.createElement('script');
      const body = document.getElementsByTagName('body')[0];
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
      body.appendChild(script);
    }
  });

  return (
    <div
      className={`meetings-iframe-container ${className}`}
      data-src={`${src}${id && `&contentId=${id}`}`}></div>
  );
};
