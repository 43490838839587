import React from 'react';
import Button from '@/components/common/Button';

interface Props {
  list: Array<any>;
  theme?: string;
  align?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const alignClass = (align: string): string => {
  switch (align) {
    case 'left':
      return 'items-start text-left';
    case 'right':
      return 'items-end text-left';
    default:
      return 'items-center text-center';
  }
};

const maxColsClass = (items: number): string => {
  switch (items) {
    case 4:
      return `grid-cols-1 sm:grid-cols-2 lg:grid-cols-4`;
    case 3:
      return `grid-cols-1 sm:grid-cols-2 lg:grid-cols-3`;
    case 2:
      return `grid-cols-1 sm:grid-cols-2`;
    case 1:
      return `grid-cols-1 md:grid-cols-1`;
    default:
      return `grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5`;
  }
};

const Highlights = ({ list, theme, style, align }: Props): JSX.Element => {
  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      <div className={`grid w-full ${maxColsClass(list.length)} gap-x-20 gap-y-10 max-w-[1440px]`}>
        {list.map(({ image, heading, text, link }) => {
          return (
            <div key={heading} className={`flex flex-col gap-5 max-w-[25rem] ${alignClass(align)}`}>
                {(image || heading || text) && (
                  <div className={`flex-1 flex flex-col w-full gap-5 ${alignClass(align)}`}>
                    {image && (
                      <img
                        alt={heading}
                        className="aspect-square w-[80px] object-cover rounded-lg"
                        src={image.url}
                      />
                    )}
                    {heading && <h4>{heading}</h4>}
                    {text && <p dangerouslySetInnerHTML={{ __html: text }}></p>}
                  </div>
                )}
                {link && <Button {...link} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(Highlights);
