import React from 'react';
//import Icon from '../Icon';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: string;
  size?: string;
  children?: any;
  selected: boolean;
}

const getClassByTheme = (theme: string, selected: boolean): string => {
  const baseClass =
    'gap-2 rounded-full flex flex-cols justify-center items-center cursor-pointer transition-all ';

  switch (theme) {
    case 'light':
      return (
        baseClass +
        (selected
          ? 'border border-solid border-black bg-black text-white'
          : 'border border-solid border-[rgba(0,0,0,0.2)] text-black hover:bg-black hover:text-white')
      );
    case 'dark':
      return (
        baseClass +
        (selected
          ? 'border border-solid border-white bg-white text-black'
          : 'border border-solid border-[rgba(255,255,255,0.2)] text-white hover:bg-white hover:text-black')
      );
    default:
      return '';
  }
};

const getClassBySize = (size: string, theme: string): string => {
  if (theme == 'link') return null;

  switch (size) {
    case 'large':
      return 'h-14 px-6';
    case 'medium':
      return 'h-10 px-4';
    default:
      return 'h-8 px-3';
  }
};

const Button = ({
  theme = 'dark',
  children = null,
  selected = false,
  size = 'medium',
  onClick,
}: ButtonProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className={`${getClassByTheme(theme, selected)} ${getClassBySize(size, theme)}`}>
      {/* <Icon type={selected ? 'checkmark' : 'plus'} className={'w-[1.5rem] stroke-w-2'} /> */}
      <span className={'whitespace-nowrap'}>{children}</span>
    </button>
  );
};

export default Button;
