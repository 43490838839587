import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@react-hook/media-query';
import BlockContent from '@sanity/block-content-to-react';
import axios from 'axios';
import classnames from 'classnames';
import classNames from 'classnames';
import md5 from 'md5';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import SubscribeButton from '@/components/buttons/Subscribe';
import serializers from '@/components/utils/serializers';

import styles from './styles.module.scss';

type Props = {
  newsletterShow: boolean;
  data: {
    header: string;
    buttonText: string;
    placeholderEmail: string;
    errorText: any[];
    privacyText: any[];
    successText: any[];
  };
};

const schema = yup.object().shape({
  email: yup.string().required().email(),
  privacy: yup.bool().oneOf([true]),
});

const Newsletter: React.FunctionComponent<Props> = ({ newsletterShow, data }): JSX.Element => {
  const {
    reset,
    register,
    formState: { errors, touchedFields, isValid, isSubmitting, isSubmitted, isSubmitSuccessful },
    handleSubmit,
    setValue,
    clearErrors,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const isPhone = useMediaQuery('(max-width: 768px)');

  const privacyAccepted = useWatch({
    control,
    name: 'privacy',
    defaultValue: false,
  });

  const [submissionError, setSubmissionError] = useState<boolean>(false);

  const onSubmit = async (formValues): Promise<void> => {
    const subscriberHash = md5(formValues.email.toLowerCase());

    try {
      await axios.put(
        `/api/mailchimp/lists/${process.env.NEXT_PUBLIC_NEWSLETTER_AUDIENCE_ID}/members/${subscriberHash}`,
        {
          email_address: formValues.email,
          status: 'subscribed',
          language: navigator.language,
        },
        {
          auth: {
            username: 'user',
            password: process.env.NEXT_PUBLIC_NEWSLETTER_API_TOKEN,
          },
        }
      );
      setSubmissionError(false);

      await reset({}, { keepIsSubmitted: true });

      TagManager.dataLayer({
        dataLayer: {
          event: 'sign_up_for_newsletter_submitted',
          email_address: formValues.email,
          language: navigator.language,
          fromUrl: window.location.href,
        },
      });
    } catch (err) {
      console.error('err', err);
      setSubmissionError(true);
    }
  };

  const onPressCheckbox = (e): void => {
    e.preventDefault();
    clearErrors('privacy');
    setValue('privacy', !privacyAccepted, { shouldDirty: true });
  };

  if (!newsletterShow) {
    return null;
  }

  return (
    <div className={styles.newsletter}>
      <div className={styles.container}>
        <h2 className={styles.header}>{data?.header}</h2>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div
            className={classNames(
              styles.inputRow,
              touchedFields.email && errors.email && styles.error
            )}>
            <div
              className={classnames(
                styles.formElement,
                styles.name,
                touchedFields.email && errors.email && styles.error
              )}>
              <input
                type="text"
                placeholder={data?.placeholderEmail}
                id="newsletterEmail"
                aria-invalid={errors.email ? 'true' : 'false'}
                {...register('email')}
              />
              <label htmlFor="newsletterEmail">Your E-mail address</label>
            </div>
            <div
              className={classnames(styles.formElement, styles.submit, isValid && styles.isValid)}>
              {!isPhone && (
                <SubscribeButton
                  arrowClassName={classNames(styles.arrow, isValid && styles.subscribeArrowIsValid)}
                  text={data?.buttonText}
                  disabled={isSubmitting}
                  type="submit"
                />
              )}
            </div>
            {errors.email && (
              <div className={styles.errorMessage}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346628 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9975 5.87902 15.1539 3.84561 13.6541 2.34585C12.1544 0.846093 10.121 0.00245139 8 0ZM7.99992 3.69231C8.18249 3.69231 8.36096 3.74644 8.51276 3.84787C8.66456 3.9493 8.78287 4.09347 8.85274 4.26214C8.9226 4.43081 8.94088 4.61641 8.90526 4.79547C8.86965 4.97453 8.78173 5.139 8.65264 5.2681C8.52354 5.39719 8.35907 5.48511 8.18001 5.52072C8.00095 5.55634 7.81535 5.53806 7.64668 5.46819C7.47801 5.39833 7.33384 5.28002 7.23241 5.12822C7.13099 4.97642 7.07685 4.79795 7.07685 4.61538C7.07685 4.49416 7.10072 4.37413 7.14711 4.26214C7.1935 4.15014 7.2615 4.04838 7.34721 3.96267C7.43293 3.87695 7.53469 3.80896 7.64668 3.76257C7.75867 3.71618 7.87871 3.69231 7.99993 3.69231H7.99992ZM8.61539 12.3077H8C7.91918 12.3077 7.83913 12.2918 7.76445 12.2609C7.68977 12.23 7.62191 12.1847 7.56476 12.1276C7.5076 12.0704 7.46228 12.0025 7.43137 11.9279C7.40046 11.8532 7.38457 11.7731 7.38462 11.6923V8C7.22141 8 7.06488 7.93516 6.94947 7.81976C6.83407 7.70435 6.76923 7.54782 6.76923 7.38461C6.76923 7.2214 6.83407 7.06488 6.94947 6.94947C7.06488 6.83406 7.22141 6.76923 7.38462 6.76923H8C8.08083 6.76918 8.16087 6.78507 8.23555 6.81598C8.31024 6.84689 8.3781 6.89221 8.43525 6.94937C8.4924 7.00652 8.53773 7.07438 8.56864 7.14906C8.59955 7.22374 8.61543 7.30379 8.61539 7.38461V11.0769C8.7786 11.0769 8.93512 11.1418 9.05053 11.2572C9.16594 11.3726 9.23077 11.5291 9.23077 11.6923C9.23077 11.8555 9.16594 12.012 9.05053 12.1274C8.93512 12.2429 8.7786 12.3077 8.61539 12.3077Z"
                    fill="#BA371C"
                  />
                </svg>
                <span>E-mail address is invalid</span>
              </div>
            )}
          </div>

          <div className={styles.inputRowBottom}>
            <div
              className={classnames(
                styles.formElement,
                styles.accept,
                errors.privacy && styles.error
              )}>
                <div className={styles.inputWrapper}>
                  <div className={styles.borderline}>
                    <div className={styles.borderlineWrapper}>
                      <input
                        type="checkbox"
                        id="privacyAccept"
                        {...register('privacy')}
                        onKeyPress={onPressCheckbox}
                      />
                    </div>
                  </div>
                </div>
                <span>
                  <BlockContent blocks={data?.privacyText} serializers={serializers} />
              </span>
            </div>
          </div>

          {isPhone && (
            <SubscribeButton text={data?.buttonText} disabled={isSubmitting} type="submit" />
          )}
        </form>
        {isSubmitted && submissionError && (
          <div className={styles.submissionFeedback}>
            <BlockContent blocks={data?.errorText} serializers={serializers} />
          </div>
        )}
        {isSubmitSuccessful && !submissionError && (
          <div className={styles.submissionFeedback}>
            <BlockContent blocks={data?.successText} serializers={serializers} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Newsletter;
