import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  theme?: 'default' | 'white';
}

const CtaArrowIcon = ({ className, theme }: Props): JSX.Element => {
  return (
    <div
      className={classNames(
        styles.ctaArrowIcon,
        theme === 'white' && styles.themeWhite,
        className
      )}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
        <path
          fill="#E6E6E6"
          d="M17.576 10.526l-8.068 8.246.713.728 9.279-9.483L10.2.5l-.712.729 8.07 8.257H.5v1.04h17.076z"
        />
      </svg>
    </div>
  );
};

export default CtaArrowIcon;
