import classNames from 'classnames';
import React from 'react';

import BlockContent from '@/components/common/PageBlockContent';
import { useTheme } from '@/components/context/theme';

import styles from './styles.module.scss';

const TextWithLabel = (props: any) => {
  const theme = useTheme();

  return (
    <div className={classNames(styles.textWithLabel, theme == 'light' && styles.themeLight)}>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h2>{props.label}</h2>
        </div>
        <div className={styles.text}>
          <BlockContent text={props.text} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(TextWithLabel);
