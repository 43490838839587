import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

/**
 * PrimaryButton is just a wrapper that
 * applies the right style to the element.
 *
 * You should pass in whatever element
 * you want to use in the children prop.
 * Eg: button, link..
 *
 * <PrimaryButton>
 *  <button />
 * </PrimaryButton>
 */
interface Props {
  children: React.ReactNode;
  theme?: 'default' | 'kyber' | 'oxford' | 'inverse';
  inactive?: boolean;
  className?: string;
}

function resolveButtonClassname(theme): string | null {
  switch (theme) {
    case 'default':
      return styles.default;
    case 'kyber':
      return styles.kyber;
    case 'oxford':
      return styles.oxford;
    case 'inverse':
      return styles.inverse;
    default:
      return null;
  }
}

function resolveInactiveClassname(inactive): string | null {
  return inactive ? styles.inactive : null;
}

const OutlineButton = ({ children, theme, inactive, className }: Props): JSX.Element => {
  return (
    <div
      className={classnames(
        styles.button,
        resolveButtonClassname(theme),
        resolveInactiveClassname(inactive),
        className,
      )}>
      {children}
    </div>
  );
};

export default OutlineButton;
