import React from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import classNames from 'classnames';

import CtaArrowIcon from '@/components/icons/cta/Arrow';

import styles from './styles.module.scss';

type Props = {
  arrowClassName?: string;
  text: string;
  [x: string]: any;
};

const SubscribeButton: React.FunctionComponent<Props> = ({
  text,
  arrowClassName,
  ...props
}): JSX.Element => {
  const isPhone = useMediaQuery('(max-width: 768px)');

  if (isPhone) {
    return (
      <button type="submit" className={styles.subscribeButtonMobile} {...props}>
        <span>{text}</span>
      </button>
    );
  }

  return (
    <button type="submit" className={styles.subscribeButton} {...props}>
      <span>{text}</span>
      <CtaArrowIcon className={classNames(styles.arrow, arrowClassName)} />
    </button>
  );
};

export default SubscribeButton;
