import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  className?: string;
}

const DownloadIcon = ({ className }: Props): JSX.Element => {
  return (
    <div className={classNames(styles.downloadIcon, className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 20"
        className={styles.arrow}>
        <path
          fill="#E6E6E6"
          d="M7.404 0h1.191v13.771l6.563-6.414.842.823L8 16 0 8.18l.842-.823 6.562 6.414V.001z"
        />
        <path stroke="#E6E6E6" d="M0 19.5h16" />
      </svg>
    </div>
  );
};

export default DownloadIcon;
