import Image from 'next/image';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import getImageDimensions from '@/components/utils/imageDimensions';
import Icon from '@/components/common/Icon';

interface Props {
  images: any;
  style?: any;
  theme?: string;
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const ImageGallery = ({ images, style, theme = 'black' }: Props): JSX.Element => {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(null);
  const [isEnd, setIsEnd] = useState(null);
  const [slidesPerView, setSlidesPerView] = useState(0);

  const onChange = (s: any): void => {
    setActiveIndex(s.activeIndex);
    setSwiper(s);
    setIsBeginning(s.isBeginning);
    setIsEnd(s.isEnd);
  };

  const onResize = (): void => {
    setSlidesPerView(
      (window as any)?.innerWidth < 768 ? 6 : Math.max(6, Math.min(12, images.length))
    );
  };

  if (images.length < 1) {
    return null;
  }

  if (images.length === 1) {
    const singleDim = getImageDimensions(images[0]);

    return (
      <div>
        <div>
          <Image alt="" src={images[0].asset.url} width={singleDim.width} height={singleDim.height} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`
    flex flex-col gap-8 items-center py-8 px-4 sm:px-12 lg:px-24 
    ${themeClass(theme)} 
    ${style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''} 
    ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''}
    `}>
      <div className="w-full max-w-[1440px] flex flex-col items-end gap-2.5  justify-center items-center">
        <Swiper
          onSwiper={(e) => {
            setThumbsSwiper(e);
            onResize();
          }}
          spaceBetween={10}
          slidesPerView={slidesPerView}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="w-full">
          {images?.map((image, index) => {
            const active = activeIndex == index;

            return (
              <SwiperSlide key={image._key} className={`w-full ${active ? 'opacity-30' : ''}`}>
                <div className="w-full aspect-video">
                  <img
                    alt=""
                    src={image.asset.url + '?fm=jpg&w=200&q=85'}
                    className="absolute h-full object-cover w-full rounded-md"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Swiper
          onSwiper={setSwiper}
          spaceBetween={10}
          navigation={true}
          onSlideChange={onChange}
          onResize={onResize}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="w-full rounded-md overflow-hidden">
          {images?.map((image) => {
            return (
              <SwiperSlide key={image._key}>
                <div className="w-full aspect-video">
                  <img
                    alt=""
                    src={image.asset.url + '?fm=jpg&w=1920&h=1080&fit=crop&q=85'}
                    className="absolute w-full h-full object-cover w-full rounded-sm"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {thumbsSwiper && swiper && (
        <div className="flex gap-5 items-center">
          <button
            className={`${
              isBeginning ? 'opacity-50' : ''
            } flex w-14 h-14 border-solid border-[1px] rounded-lg justify-center items-center hover:bg-[#eee] hover:text-black`}
            onClick={() => {
              swiper.slidePrev();
            }}>
            <Icon className="w-[1.5rem]" type="arrowleft" />
          </button>
          {/* <span className="w-12 flex justify-center">{`${activeIndex + 1}/${testimonials.length
          }`}</span> */}
          <button
            className={`${
              isEnd ? 'opacity-50' : ''
            } flex w-14 h-14 border-solid border-[1px] rounded-lg justify-center items-center hover:bg-[#eee] hover:text-black`}
            onClick={() => {
              swiper.slideNext();
            }}>
            <Icon className="w-[1.5rem]" type="arrowright" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
