import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import Button from '@/components/common/Button';
import serializers from '@/components/bits/SimpleBlockContent/Serializer';
import titleSerializer from '@/components/utils/serializers/title';

interface Props {
  theme: string;
  layout: string;
  image: any;
  heading: string;
  text: any;
  ctas: any;
  style: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const ImageTextBanner = ({
  theme,
  layout,
  image,
  heading,
  text,
  ctas,
  style,
}: Props): JSX.Element => {
  const layoutClass = layout == 'right' ? 'lg:flex-row-reverse' : 'lg:flex-row';

  if (!image) return null;

  const imageUrl =
    image.url.includes('.gif') || image.url.includes('.png')
      ? image.url + '?fit=crop&h=1200&w=1200&q=85'
      : image.url + '?fm=jpg&h=1200&w=1200&q=85';

  return (
    <div
      className={`${themeClass(theme)} flex justify-center py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
        } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
        <div
          className={`w-full max-w-[1440px] flex items-start lg:items-center flex-col gap-10 md:gap-20 ${layoutClass}`}>
          {image && (
            <div className="flex-1 w-full">
              <div className="aspect-square w-full">
                <img
                  alt={image.title || ''}
                  className="w-full flex shrink-0 flex-1 h-full absolute object-cover rounded-lg"
                  src={imageUrl}
                />
              </div>
            </div>
          )}
          <div className="relative gap-7 md:gap-10 flex flex-1 justify-center flex-col">
            {heading && (
              <h2 className="max-w-[600px]">
                <BlockContent
                  className="gap-5 flex flex-col"
                  blocks={heading}
                  serializers={titleSerializer}
                />
              </h2>
            )}
            {text && (
              <div className="max-w-[500px]">
                <BlockContent
                  className="gap-5 flex flex-col"
                  blocks={text}
                  serializers={serializers}
                />
              </div>
            )}
            {ctas?.length > 0 && (
              <div className="flex flex-row flex-wrap gap-8">
                {ctas.map((cta) => (
                  <Button key={cta._key} {...cta} />
                ))}
              </div>
            )}
          </div>
      </div>
    </div>
  );
};

export default React.memo(ImageTextBanner);
