import React from 'react';

import plug from '../Plug';
import styles from './index.module.scss';

const NoPlug = ({ type }: {
  type: string
}): JSX.Element => (
  <div className={styles.root}>
    <span>{type}</span>
  </div>
);

export default plug(NoPlug);
