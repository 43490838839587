import React from "react";

interface Props {
  video: any;
  loop: boolean;
  autoplay: boolean;
  style?: any;
  theme?: string;
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const Video = ({
  theme = 'black',
  style,
  video,
  autoplay = false,
  loop = false,
}: Props): JSX.Element => {
  if (!video.asset.url) return null;

  return (
    <div
      className={`
      flex justify-center py-8 px-4 sm:px-12 lg:px-24
      ${themeClass(theme)} 
      ${style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''} 
      ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''}
    `}>
      <div className="w-full max-w-[1440px]">
        <div className="w-full">
          <video
            className="w-full h-auto rounded-md"
            autoPlay={autoplay}
            loop={loop}
            muted={autoplay}
            controls={!autoplay}
            src={video.asset.url}
          />
        </div>
      </div>
    </div>
  );
};

export default Video;
