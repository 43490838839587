import React from 'react';
import LazyLoad from 'react-lazyload';
import MarqueeComponent from '@/components/common/Marquee';

interface Props {
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
  logos: [];
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-[#111] text-white';
  }
};

const Marquee = ({
  theme = 'black',
  style = {
    paddingBottom: true,
    paddingTop: true,
  },
  logos,
}: Props): JSX.Element => {
  if (!logos || logos.length <= 0) return null;

  return (
    <div
      className={`${themeClass(
        theme
      )} overflow-hidden relative gap-10 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''}`}>
      <LazyLoad once offset={1000}>
        <MarqueeComponent items={logos} />
      </LazyLoad>
    </div>
  );
};

export default Marquee;
