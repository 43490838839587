import React from 'react';
import Button from '@/components/common/Button';

interface Props {
  theme?: string;
  text?: string;
  ctas?: any[];
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-primary text-black';
    default:
      return 'bg-black text-white';
  }
};

const Banner = ({ theme = 'black', text, ctas }: Props): JSX.Element => {
  return (
    <div
      className={`${themeClass(
        theme
      )} flex justify-center items-center text-center py-8 px-4 sm:px-12 lg:px-24`}>
      <div className="w-full max-w-[1440px] gap-5 md:gap-10 flex justify-center items-center text-center flex-col md:flex-row ">
        {text && (
          <h4 className="max-w-[900px] text-center md:text-center">{text}</h4>
        )}
        {ctas?.length > 0 && (
          <div className="flex flex-row flex-wrap justify-center gap-8">
            {ctas.map((cta) => (
              <Button key={cta.title} {...cta} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
