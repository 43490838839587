import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import DownloadIcon from '@/components/icons/Download';

import styles from './styles.module.scss';

interface Props {
  href?: any;
  className?: string;
  children: React.ReactNode;
}

const DownloadButton = ({ href, className, children }: Props): JSX.Element => {
  if (href) {
    return (
      <Link href={href} legacyBehavior>
        <a className={classNames(styles.downloadLink, className)}>
          <div className={styles.spacer}>
            <DownloadIcon />
          </div>
          {children}
        </a>
      </Link>
    );
  }
};

export default DownloadButton;
