import classNames from 'classnames';
import React from 'react';

import SimpleBlockContent from '@/components/bits/SimpleBlockContent/SimpleBlockContent';
import Banner from '@/components/common/Banner';
import { useTheme } from '@/components/context/theme';

import styles from './styles.module.scss';

interface TwoColumnTextBannerProps {
  label: string;
  rows: any;
}

const TwoColumnTextBanner = ({ label, rows }: TwoColumnTextBannerProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Banner
      label={label}
      className={theme == 'light' ? styles.theme : null}
      hideLabel={!label}>
      <div className={classNames(styles.twoColumnTextBanner, !label && styles.noPaddingTop)}>
        <div className={styles.content}>
          {rows?.map((row: any) => (
            <React.Fragment key={row._key}>
              <div className={styles.leftColumn} key={`${row._key}-1`}>
                {row.leftColumn && <SimpleBlockContent data={row.leftColumn} />}
              </div>
              <div className={styles.rightColumn} key={`${row._key}-2`}>
                {row.rightColumn && <SimpleBlockContent data={row.rightColumn} />}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </Banner>
  );
};

export default TwoColumnTextBanner;
