import React from 'react';

import Banner from '@/components/common/Banner';

import plug from '../Plug';
import CaseListItem from './CaseListItem';
import styles from './styles.module.scss';

interface Props {
  heading: string;
  subheading: string;
  cases: Array<any>;
}

function datapoints(c): [] {
  const textContent = c.otherContent?.content.find((el) => el._type === 'textContent');
  if (!textContent) return [];

  const datapointsContent = textContent.text.find((el) => el._type === 'datapoints');
  if (!datapointsContent) return [];

  return datapointsContent.datapoints;
}

const SelectedCases = ({ heading, subheading, cases }: Props): JSX.Element => {
  if (!cases) return null;
  return (
    <Banner label="Selected Cases" className={styles.banner}>
      <div className={styles.content}>
        <div className={styles.intro}>
          <p>{heading}</p>
          <p>{subheading}</p>
        </div>
        <ul className={styles.caseList}>
          {cases.map((c, i) => {
            return (
              <CaseListItem
                key={i}
                {...c}
                title={c.title}
                developer={c.developerName}
                imageUrl={c.imageUrl}
                description={c.description}
                slug={c.slug.current}
                datapoints={datapoints(c)}
              />
            );
          })}
        </ul>
      </div>
    </Banner>
  );
};
export default plug(SelectedCases);
