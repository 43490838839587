import React from 'react';

import Banner from '@/components/common/Banner';
import DownloadButton from '@/components/common/buttons/DownloadButton';

import styles from './styles.module.scss';

interface Props {
  label: string;
  heading: string;
  lead: string;
  ctaTitle: string;
  asset: any;
}

const DownloadablesBanner = ({ label, heading, lead, ctaTitle, asset }: Props): JSX.Element => {
  return (
    <Banner className={styles.downloadablesBanner} labelClassName={styles.label} label={label}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <h2>{heading}</h2>
        </div>
        <div className={styles.lead}>
          <p>{lead}</p>
        </div>
        <div className={styles.cta}>
          {asset && asset.url && (
            <DownloadButton href={`${asset.url}?dl`}>{ctaTitle}</DownloadButton>
          )}
        </div>
      </div>
    </Banner>
  );
};

export default DownloadablesBanner;
