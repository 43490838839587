import keys from 'lodash/keys';
import React from 'react';

import accordion from './Accordion'; /* webpackPreload: true */
import casesSelected from './CasesSelected'; /* webpackPreload: true */
import ctaBanner from './CTABanner'; /* webpackPreload: true */
import downloadablesBanner from './DownloadablesBanner'; /* webpackPreload: true */
import emailList from './EmailList';
import employeeList from './EmployeesList'; /* webpackPreload: true */
import fullscreenImage from './FullscreenImage'; /* webpackPreload: true */
import hero from './Hero'; /* webpackPreload: true */
import imageBanner from './ImageBanner'; /* webpackPreload: true */
import jobList from './JobList'; /* webpackPreload: true */
import latestNews from './LatestNews'; /* webpackPreload: true */
import linkToPageBanner from './LinkToPageBanner'; /* webpackPreload: true */
import marquee from './Marquee'; /* webpackPreload: true */
import newsletter from './Newsletter'; /* webpackPreload: true */
import newsList from './NewsList'; /* webpackPreload: true */
import NoPlug from './NoPlug'; /* webpackPreload: true */
import officesBanner from './OfficesBanner'; /* webpackPreload: true */
import pageHeader from './PageHeader'; /* webpackPreload: true */
import defaultText from './PortableText'; /* webpackPreload: true */
import requestDemo from './RequestDemo'; /* webpackPreload: true */
import testimonialsBanner from './TestimonialsBanner'; /* webpackPreload: true */
import textSection from './TextWithLabel'; /* webpackPreload: true */
import twoColumnTextBanner from './TwoColumnTextBanner'; /* webpackPreload: true */
import imageTextBanner from './ImageTextBanner'; /* webpackPreload: true */
import videoTextBanner from './VideoTextBanner'; /* webpackPreload: true */
import highlights from './Highlights'; /* webpackPreload: true */
import sectionHeader from './SectionHeader'; /* webpackPreload: true */
import animatedSectionHeader from './AnimatedSectionHeader'; /* webpackPreload: true */
import banner from './Banner'; /* webpackPreload: true */
import tabs from './Tabs'; /* webpackPreload: true */
import cards from './Cards'; /* webpackPreload: true */
import ourOffering from './OurOffering';
import tinyBanner from './TinyBanner';
import vimeo from './Vimeo';
import imageGalleryModule from './ImageGallery';
import contactForm from './ContactForm';
import videoModule from './Video';
import pageList from './PageList';
import demoForm from './DemoForm';

// Load all as dynamic imports
const enabledPlugs = {
  hero,
  banner,
  tabs,
  sectionHeader,
  animatedSectionHeader,
  highlights,
  imageGalleryModule,
  accordion,
  videoTextBanner,
  tinyBanner,
  casesSelected,
  marquee,
  latestNews,
  pageHeader,
  cards,
  vimeo,
  fullscreenImage,
  ctaBanner,
  testimonialsBanner,
  employeeList,
  imageBanner,
  officesBanner,
  requestDemo,
  imageTextBanner,
  newsletter,
  downloadablesBanner,
  jobList,
  linkToPageBanner,
  newsList,
  defaultText,
  textSection,
  emailList,
  twoColumnTextBanner,
  ourOffering,
  contactForm,
  videoModule,
  pageList,
  demoForm,
};

type Plug = {
  _type: string;
  _key: string;
  plugs: Plug[];
};

type Props = {
  plugs: Plug[];
};

class PlugsResolver extends React.Component<Props> {
  resolvePlug = (plug: { _type: string; _key: string; content?: any }): JSX.Element => {
    const plugType = plug._type;
    const plugComponent = plug;

    if (plugType == 'globalComponent' && plug.content) {
      return plug.content.map((p) => {
        const Plug = enabledPlugs[p._type];
        return <Plug {...p} key={p._key} />;
      });
    }

    const Plug = enabledPlugs[plugType];

    if (Plug) {
      return <Plug {...plugComponent} key={plug._key} />;
    }

    return <NoPlug type={plug._type} key={plug._key} />;
  };

  render(): any {
    const { plugs } = this.props;

    if (!plugs) {
      return <div>No plugs</div>;
    }

    return plugs.map((plug) => {
      if (keys(plug).length === 0) {
        return <div key={plug._key}>Wait for conditional in GROQ</div>;
      }

      return plug._type === 'plugComposition'
        ? plug.plugs.map((plugFromComposition) => this.resolvePlug(plugFromComposition))
        : this.resolvePlug(plug);
    });
  }
}

export default PlugsResolver;
