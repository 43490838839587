export const query = /* groq */ `
    "investorRelations": *[_type=='investorRelation'] | order(dateTime(_createdAt) desc) {
      _createdAt,
      ...elements {
        ...,
        files[] {
          ...,
          "url": asset->url
        }
      },
    }
`;

export default query.replace(/ +/g, '');
