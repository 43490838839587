import React, { useEffect, useState } from 'react';
import ImageTextBanner from '../ImageTextBanner';
import Tab from '@/components/common/Tab';

interface Props {
  tabType: 'regular' | 'timeline';
  tabs?: any;
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const Tabs = ({ tabType, tabs, style, theme }: Props): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);
  const [subTabIndex, setSubTabIndex] = useState(0);
  const [content, setContent] = useState(null);

  useEffect(() => {
    let c = null;

    if (tabs[tabIndex]?.content !== null) {
      c = tabs[tabIndex].content;
    }

    if (tabs[tabIndex]?.tabs && tabs[tabIndex].tabs[subTabIndex]?.content !== null) {
      c = tabs[tabIndex].tabs[subTabIndex].content;
    }

    setContent(c);
  }, [tabs, tabIndex, subTabIndex]);

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-10 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''}`}>
      {tabs && tabType == 'timeline' && (
        <div className="flex max-w-full scroll-smooth flex-auto flex-nowrap white-space-nowrap overflow-x-auto px-4 sm:px-12 lg:px-24">
          {tabs.map((tab, index) => (
            <React.Fragment key={`tab${index}`}>
              {index !== 0 && (
                <div className="flex flex-col gap-10">
                  <div className="flex items-center h-[1rem]">
                    <span
                      className={`flex h-1 w-3 flex-1 ${
                        index > tabIndex ? 'bg-[#333]' : 'bg-white'
                      }`}></span>
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-10 basis-full">
                <div className="flex items-center">
                  <span
                    className={`flex h-1 flex-1 ${
                      index > 0 &&
                      (tabIndex == index
                        ? 'bg-gradient-to-r from-white to-[#21C980]'
                        : index > tabIndex
                        ? 'bg-[#333]'
                        : 'bg-white')
                    }`}></span>
                  <span
                    className={`mx-[-2px] relative w-[1rem] h-[1rem] ${
                      tabIndex == index ? 'bg-primary' : index > tabIndex ? 'bg-[#333]' : 'bg-white'
                    } flex flex-grow-0 rounded-full`}></span>
                  <span
                    className={`flex h-1 flex-1 ${
                      index < tabs.length - 1 && (index >= tabIndex ? 'bg-[#333]' : 'bg-white')
                    }`}></span>
                </div>
                <Tab
                  key={`tab${index}`}
                  theme="dark"
                  size="medium"
                  selected={tabIndex == index}
                  onClick={() => {
                    setTabIndex(index);
                    setSubTabIndex(0);
                  }}>
                  {tab.label}
                </Tab>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {tabs && tabType == 'regular' && (
        <div className="flex gap-3 flex-wrap justify-center px-10">
          {tabs.map((tab: any, index: number) => (
            <Tab
              key={`tab${index}`}
              theme="dark"
              size="large"
              selected={tabIndex == index}
              onClick={() => {
                setTabIndex(index);
                setSubTabIndex(0);
              }}>
              {tab.label}
            </Tab>
          ))}
        </div>
      )}
      {tabs && tabs[tabIndex].tabs?.length > 0 && (
        <div className="flex gap-3 flex-wrap justify-center px-10">
          {tabs[tabIndex].tabs.map((subtab: any, index: number) => (
            <Tab
              key={`tab${tabIndex}-${index}`}
              theme="dark"
              size="small"
              selected={subTabIndex == index}
              onClick={() => setSubTabIndex(index)}>
              {subtab.label}
            </Tab>
          ))}
        </div>
      )}
      {content && (
        <div className="flex">
          <ImageTextBanner {...content} style={null} />
        </div>
      )}
    </div>
  );
};

export default React.memo(Tabs);
