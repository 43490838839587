import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import Button from '@/components/common/Button';

interface Props {
  backgroundVideoMobile?: any;
  backgroundVideo?: any;
  backgroundImages?: any;
  title: string;
  text?: any;
  ctas?: any[];
  children?: any;
}

const Banner = ({
  backgroundVideoMobile,
  backgroundVideo,
  backgroundImages,
  title,
  text,
  ctas,
  children,
}: Props): JSX.Element => {
  const renderBackground = (): JSX.Element => {
    if (backgroundVideo || backgroundVideoMobile) {
      return (
        <video
          className="w-full h-full object-cover opacity-30"
          muted
          autoPlay
          playsInline
          loop
          src={(backgroundVideo || backgroundVideoMobile).asset.url}
        />
      );
    }

    if (backgroundImages && backgroundImages.length > 0) {
      return (
        <img
          alt={backgroundImages[0].title || ''}
          className="w-full h-full object-cover opacity-30"
          src={backgroundImages[0].asset.url + '?fm=jpg&w=1440&q=85'}
        />
      );
    }

    return null;
  };

  return (
    <div className="relative">
      <div className="bg-black absolute top-0 left-0 w-full h-full z-0 bg-black">
        {renderBackground()}
      </div>

      <div className="relative gap-5 md:gap-10 flex justify-center items-center text-center flex-col py-[5rem] md:py-[10rem] px-4 sm:px-12 lg:px-24">
        {title && (
          <h2 className="max-w-[900px]">{title}</h2>
        )}
        {text && (
            <div className="max-w-[800px]">
              {typeof text == 'string' ? (
                <div className="gap-5 flex flex-col">{text}</div>
              ) : (
                <BlockContent className="gap-5 flex flex-col" blocks={text} />
              )}
          </div>
        )}
        {ctas?.length > 0 && (
          <div className="flex flex-row flex-wrap justify-center gap-8">
            {ctas.map((cta) => (
              <Button key={cta.title} {...cta} />
            ))}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Banner;
