import React from "react";

interface Props {
  vimeoUrl: string;
  style?: any;
  theme?: string;
  autoplay?: boolean;
  loop?: boolean;
  controls?: boolean;
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const Vimeo = ({
  theme = 'black',
  style,
  vimeoUrl,
  autoplay = false,
  loop = false,
  controls = true,
}: Props): JSX.Element => {
  let src: string | null = null;

  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const parseUrl = vimeoUrl.match(regExp);
  const autoplayStr = autoplay ? '&autoplay=1&muted=1' : '&autoplay=0';
  const loopStr = loop ? '&loop=1' : '&loop=0';
  const controlsStr = controls ? '&controls=1' : '&controls=0';

  if (parseUrl) {
    const vimeoId = parseUrl[5];
    src = `https://player.vimeo.com/video/${vimeoId}?h=e00faf987a&color=ffffff&title=0&portrait=0${autoplayStr}${loopStr}${controlsStr}`;
  }

  if (!src) return null;

  return (
    <div
      className={`
      flex justify-center py-8 px-4 sm:px-12 lg:px-24
      ${themeClass(theme)} 
      ${style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''} 
      ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''}
    `}>
      <div className="w-full max-w-[1440px]">
        <div className="aspect-video w-full">
          <iframe
            title="embedded content"
            width="100%"
            className="absolute w-full h-full rounded-lg"
            allowFullScreen
            src={src}></iframe>
        </div>
      </div>
    </div>
  );
};

export default Vimeo;
