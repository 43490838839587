import Badge from '@/components/common/Badge';
import React, { useState } from 'react';
import Tab from '@/components/common/Tab';
import Link from 'next/link';
import { urlFor } from '@/components/utils';

const ALL_NEWS_TITLE = 'All';

interface Props {
  categoryFilters?: any;
  selectedArticles?: any;
  allNews: any;
  showFilter?: boolean;
  heading?: string;
  text?: any;
  limit?: number;
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
}
const themeClass = (theme): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const NewsList = ({
  categoryFilters = [],
  selectedArticles = [],
  allNews = [],
  showFilter = false,
  limit = null,
  style = null,
  theme = 'black',
}: Props): JSX.Element => {
  const [newsCategoryIndex, setNewsCategoryIndex] = useState(0);

  if (allNews?.length < 1 && selectedArticles?.length < 0) return null;

  let allFilters = allNews.reduce(
    (arr, curr) => [...arr, ...curr.categories.map((cat) => ({ title: cat.title }))],
    []
  );

  allFilters = Array.from(
    new Set(allFilters.map((obj) => JSON.stringify(obj)))
  ).map((string: string) => JSON.parse(string));

  const categories = ((categoryFilters?.length > 0 ? categoryFilters : null) || allFilters).map(
    ({ title }) => ({
      title,
      news: allNews
        .filter((article) => article.categories?.some((category) => category.title === title))
        .sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()),
    })
  );

  let tabs = [
    {
      title: ALL_NEWS_TITLE,
      news: allNews,
    },
    ...categories,
  ];

  if (selectedArticles?.length > 0) {
    tabs = [
      {
        title: ALL_NEWS_TITLE,
        news: selectedArticles.sort((a, b) => {
          return new Date(b._createdAt).getTime() - new Date(a._createdAt).getTime();
        }),
      },
    ];
  }

  const getActiveNews = (): any => {
    const arr = tabs[newsCategoryIndex].news;
    return limit ? arr.splice(0, limit) : arr;
  };

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-10 md:gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      {showFilter && tabs && (
        <div className="flex gap-3 flex-wrap justify-center">
          {tabs.map((tab, index) => (
            <Tab
              key={index + tab.title}
              theme="dark"
              selected={tabs[newsCategoryIndex].title == tab.title}
              onClick={() => setNewsCategoryIndex(index)}>
              {tab.title}
            </Tab>
          ))}
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-20 gap-y-20 w-full max-w-[1440px]">
        {getActiveNews().map((article, index) => {
          const imageUrl = urlFor(article.image).width(800).height(450).url();

          return (
            <Link key={index + article.title} href={'/' + article.slug.current} passHref legacyBehavior>
              <a className="flex flex-col gap-5">
                {article.image && (
                  <div className="aspect-video w-full">
                    <img
                      src={imageUrl}
                      alt={article.title}
                      className="rounded-md w-full h-full absolute object-cover"
                    />
                  </div>
                )}
                {article.categories?.length > 0 && (
                  <div className="flex gap-2 flex-wrap">
                    {article.categories.map((cat) => (
                      <Badge key={cat.title} theme="green">
                        {cat.title}
                      </Badge>
                    ))}
                  </div>
                )}
                {article.title && <h5>{article.title}</h5>}
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default NewsList;
