import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Button from '@/components/common/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const alignClass = (align: string): string => {
  switch (align) {
    case 'left':
      return 'justify-start text-left';
    case 'right':
      return 'justify-end text-left';
    default:
      return 'justify-center text-center';
  }
};

interface Props {
  label?: string;
  headings: any[];
  text?: any;
  ctas?: any;
  theme?: string;
  align?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
  children?: any;
}

const AnimatedSectionHeader = ({
  label,
  headings,
  text,
  ctas,
  theme = 'black',
  style,
  align = 'center',
  children,
}: Props): JSX.Element => {
  if (!headings || headings.length <= 0) {
    return null;
  }

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      <div className={`max-w-[1440px] w-full flex ${alignClass(align)}`}>
        <div className="flex flex-col gap-5 md:gap-10 w-full max-w-[800px]">
          {label && <p className="text-lg">{label}</p>}
          {headings && (
            <Swiper
              className="w-full h-full"
              modules={[EffectFade, Autoplay]}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}>
              {headings.map((h, i) => {
                return (
                  <SwiperSlide key={i + h}>
                    <div className="w-full flex justify-center">
                      <div className="flex flex-col items-center gap-5 w-full max-w-[800px]">
                        <h2>{h}</h2>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}

          {text && (typeof text == 'string' ? text : <BlockContent blocks={text} />)}
          {ctas?.length > 0 && (
            <div className="flex flex-row flex-wrap justify-center gap-8">
              {ctas.map((cta) => (
                <Button key={cta._key} {...cta} />
              ))}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AnimatedSectionHeader);
