import React from 'react';

import classNames from 'classnames';

import InternalLinkRenderer from '@/components/bits/ArticlePortableText/Serializer/components/InternalLinkRenderer';

import styles from './styles.module.scss';

interface Props {
  node: {
    style: string;
  };
  children: any;
}

const BlockRenderer = ({ node, children }: Props): JSX.Element => {
  switch (node.style) {
    case 'normal':
      return <p className={styles.body}>{children}</p>;
    case 'bodySmall':
      return <p className={classNames(styles.body, styles.bodySmall)}>{children}</p>;
    case 'bodyLarge':
      return <p className={classNames(styles.body, styles.bodyLarge)}>{children}</p>;
    default:
      return <p>{children}</p>;
  }
};

const serializers = {
  types: {
    block: BlockRenderer,
  },
  marks: {
    internalLink: InternalLinkRenderer,
  },
};

export default serializers;
