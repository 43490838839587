import { yupResolver } from '@hookform/resolvers/yup';
import BlockContent from '@sanity/block-content-to-react';
import axios from 'axios';
import classnames from 'classnames';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import serializers from '@/components/utils/serializers';

import styles from './styles.module.scss';

type Props = {
  demoShow: boolean;
  data: {
    header: string;
    intro: string;
    buttonText: string;
    errorText: any[];
    placeholderCompanyName: string;
    placeholderEmail: string;
    placeholderFirstname: string;
    placeholderLastname: string;
    placeholderPhone: string;
    privacyText: any[];
    successText: any[];
  };
};

const schema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  company: yup.string().required(),
  email: yup.string().required().email(),
  phone: yup.string().min(8).required(),
  privacy: yup.bool().oneOf([true]),
});

const RequestDemo: React.FunctionComponent<Props> = ({ demoShow, data }): JSX.Element => {
  const {
    reset,
    register,
    formState: { errors, isValid, isSubmitting, isSubmitted, isSubmitSuccessful },
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const privacyAccepted = useWatch({
    control,
    name: 'privacy',
    defaultValue: false,
  });

  const formUrl = process.env.NEXT_PUBLIC_REQUEST_DEMO_URL;
  if (!formUrl) console.warn('Missing NEXT_PUBLIC_REQUEST_DEMO_URL');

  const [submissionError, setSubmissionError] = useState<boolean>(false);

  const onSubmit = async (formValues): Promise<void> => {
    if (formUrl) {
      try {
        await axios.get(formUrl, {
          params: {
            company: formValues.company,
            email: formValues.email,
            firstname: formValues.firstname,
            lastname: formValues.lastname,
            phone: formValues.phone,
          },
        });

        setSubmissionError(false);

        await reset({}, { keepIsSubmitted: true });

        TagManager.dataLayer({
          dataLayer: {
            event: 'request_a_demo_submitted',
            company: formValues.company,
            email: formValues.email,
            firstname: formValues.firstname,
            lastname: formValues.lastname,
            phone: formValues.phone,
            fromUrl: window.location.href,
          },
        });
      } catch (err) {
        console.log('err', err);
        setSubmissionError(true);
      }
    }
  };

  const onKeyPressPrivacy = (e): void => {
    e.preventDefault();
    clearErrors('privacy');
    const value = getValues('privacy');
    setValue('privacy', !value, { shouldDirty: true });
  };

  if (!demoShow || !data) return null;

  return (
    <section className={styles.container}>
      <h2 className={styles.header}>{data?.header}</h2>
      <p className={styles.text}>{data?.intro}</p>
      <form action="" className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.gridContainer}>
          <div
            className={classnames(
              styles.formElement,
              /*touchedFields.firstname && */ errors.firstname && styles.error
            )}>
            <input
              type="text"
              placeholder={data?.placeholderFirstname}
              id="requestDemoFirstname"
              {...register('firstname')}
            />
            <label htmlFor="requestDemoFirstname">First name</label>
          </div>
          <div
            className={classnames(
              styles.formElement,
              /*touchedFields.lastname &&*/ errors.lastname && styles.error
            )}>
            <input
              type="text"
              placeholder={data?.placeholderLastname}
              id="requestDemoLastname"
              {...register('lastname')}
            />
            <label htmlFor="requestDemoLastname">Last name</label>
          </div>
          <div
            className={classnames(
              styles.formElementLarge,
              /*touchedFields.company &&*/ errors.company && styles.error
            )}>
            <input
              type="text"
              placeholder={data?.placeholderCompanyName}
              id="requestDemoCompany"
              {...register('company')}
            />
            <label htmlFor="requestDemoCompany">Company</label>
          </div>
          <div
            className={classnames(
              styles.formElement,
              /*touchedFields.email &&*/ errors.email && styles.error
            )}>
            <input
              type="text"
              placeholder={data?.placeholderEmail}
              id="requestDemoEmail"
              {...register('email')}
            />
            <label htmlFor="requestDemoEmail">E-mail</label>
          </div>
          <div
            className={classnames(
              styles.formElement,
              /*touchedFields.phone &&*/ errors.phone && styles.error
            )}>
            <input
              type="text"
              placeholder={data?.placeholderPhone}
              id="requestDemoPhone"
              {...register('phone')}
            />
            <label htmlFor="requestDemoPhone">Phone</label>
          </div>
        </div>
        <div
          className={classnames(
            styles.privacy,
            privacyAccepted && styles.valid,
            /*touchedFields.privacy &&*/ errors.privacy && styles.error
          )}>
            <div className={styles.inputWrapper}>
              <div className={styles.borderline}>
                <div className={styles.borderlineWrapper}>
                  <input
                    type="checkbox"
                    id="requestDemoPrivacy"
                    {...register('privacy')}
                    onKeyPress={onKeyPressPrivacy}
                  />
                </div>
              </div>
            </div>
          <BlockContent blocks={data?.privacyText} serializers={serializers} />
        </div>
        <button
          type="submit"
          className={classnames(styles.submitButton, isValid && styles.validButton)}
          disabled={/*!isValid || */ isSubmitting}>
          {data?.buttonText}
        </button>
      </form>
      {isSubmitted && submissionError && (
        <div className={styles.submissionFeedback}>
          <p>{data?.errorText}</p>
        </div>
      )}
      {isSubmitSuccessful && !submissionError && (
        <div className={styles.submissionFeedback}>
          <p>{data?.successText}</p>
        </div>
      )}
    </section>
  );
};

export default RequestDemo;
