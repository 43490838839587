import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Button from '@/components/common/Button';

interface Props {
  cards: any;
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
}
const themeClass = (theme): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const Cards = ({ cards = [], style = null, theme = 'black' }: Props): JSX.Element => {
  if (cards.length <= 0) return <div>Missing cards</div>;

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-10 md:gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-20 gap-y-20 max-w-[1440px]">
        {cards?.map((card) => {
          return (
            <Link key={card._key} href={'/' + card.slug} passHref legacyBehavior>
              <a className="flex flex-col gap-5">
                {card.image?.asset?.url && (
                  <div className="aspect-video relative">
                    <Image
                      src={card.image?.asset?.url + '?w=800&h=450&crop=focalpoint&fit=crop'}
                      alt={card.heading}
                      layout="fill"
                      className="rounded-md object-cover"
                    />
                  </div>
                )}
                {card.heading && <h3>{card.heading}</h3>}
                {card.text && <p className="flex flex-1">{card.text}</p>}
                {card.slug && (
                  <div className="flex">
                    <Button title="Read more" theme="whiteOutline" size="large" noRoute />
                  </div>
                )}
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Cards;
