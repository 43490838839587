import React from 'react';

import styles from './styles.module.scss';

interface Props {
  emails: [
    {
      _key: string;
      label: string;
      emailAddress: string;
    }
  ];
}

const EmailList = ({ emails }: Props): JSX.Element => {
  return (
    <div className={styles.emailList}>
      <ul>
        {emails.map((email) => (
          <li key={email._key}>
            <p className={styles.tLabel}>{email.label}</p>
            <p className={styles.tEmailAddress}>{email.emailAddress}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(EmailList);
