import React from 'react';
import Badge from '@/components/common/Badge';
import Button from '@/components/common/Button';
import Tab from '@/components/common/Tab';
import Link from 'next/link';
import { useEffect, useState } from 'react';

interface Props {
  showFilter: boolean;
  itemsPerPage: number;
  style: {
    paddingTop: string;
    paddingBottom: string;
  };
  jobs: [any];
}

const JobList = ({ jobs, showFilter, style }: Props): JSX.Element => {
  const [currentTab, setCurrentTab] = useState('All');
  const [tabs, setTabs] = useState(null);

  useEffect(() => {
    const t = [
      {
        title: 'All',
        slug: 'all',
        length: jobs.length,
      },
    ];

    jobs.forEach((job) => {
      if (job.department && t.filter((e) => e.slug === job.department.slug).length == 0) {
        t.push({
          ...job.department,
          length: jobs.filter((j) => j.department?.title == job.department.title).length,
        });
      }
    });

    setTabs(t);
  }, [jobs]);

  return (
    <div
      className={`bg-black text-white relative gap-10 md:gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      {showFilter && tabs && (
        <div className="flex gap-3 flex-wrap justify-center">
          {tabs.map((tab, index) => (
            <Tab
              size="medium"
              key={index + tab.title}
              onClick={() => setCurrentTab(tab.title)}
              selected={currentTab == tab.title}>{`${tab.title} (${tab.length})`}</Tab>
          ))}
        </div>
      )}
      <ul className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 flex-col w-full max-w-[1440px]">
        {jobs
          .filter((job) => job.department?.title == currentTab || currentTab == 'All')
          .map((job) => (
            <li key={job._rev} className="bg-[#222] rounded-md flex">
              <Link href={job.redirectToExternalPage ? job.externalUrl : `/${job.slug}`} passHref legacyBehavior>
                <a className="flex flex-col w-full gap-5 p-8 items-start">
                  <Badge theme="white">{job.department?.title || 'All'}</Badge>
                  <h4>{job.title}</h4>
                  <div className="flex flex-1">
                    {job.type} • {job.location}
                  </div>
                  <Button noRoute size="small" theme="secondary" title="See position" />
                </a>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default JobList;
