export const query = /* groq */ `
  categoryFilters[]->{
    title
  },
  selectedArticles[]->{
    _createdAt,
    ...elements {
      publishedAt,
      categories[]->{
        title,
      },
      slug,
      title,
      "image": mainImage {
        ...,
        asset->
      },
    }
  },
  "limit": coalesce(limit, null),
  "allNews": *[_type=='news' && elements.hidden!=true] | order(dateTime(elements.publishedAt) desc) {
    _createdAt,
    ...elements {
      publishedAt,
      categories[]->{
        title,
      },
      slug,
      title,
      "image": mainImage {
        ...,
        asset->
      },
    }
  }
`;

export default query.replace(/ +/g, '');
