import { useMediaQuery } from '@react-hook/media-query';
import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import React, { useRef } from 'react';
import serializers from './Serializer';
import styles from './styles.module.scss';
import Icon from '@/components/common/Icon';

interface Props {
  headline: string;
  subHeadline: string;
  lead: any[];
  showScrollButton?: boolean;
  scrollTitle?: string;
}

const PageHeader = ({
  headline,
  subHeadline,
  lead,
  showScrollButton,
  scrollTitle,
}: Props): JSX.Element => {
  const isPhone = useMediaQuery('(max-width: 768px)');
  const ref = useRef(null);

  const handleScrollDown = (): void => {
    let scrollToElement = ref?.current?.nextSibling;
    if (!scrollToElement) return;

    let headerOffset = isPhone ? 64 : 10;
    if (scrollToElement.id === 'fullscreenImage') {
      headerOffset = isPhone ? 64 : 0;
      scrollToElement = scrollToElement?.nextSibling;
    }

    if (!scrollToElement) return;

    const elementPosition = scrollToElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={classNames(styles.pageHeader, showScrollButton && styles.scrollButtonSpacing)}
      ref={ref}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>{headline}</h1>
        </div>
        <div className={styles.text}>
          {subHeadline && <p className={styles.subheader}>{subHeadline}</p>}
          {lead && <BlockContent blocks={lead} serializers={serializers} />}
          {showScrollButton && (
            <div className={styles.explore}>
              <button onClick={handleScrollDown}>
                <Icon type="arrowdown" /> {scrollTitle || 'Explore solution'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
