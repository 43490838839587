import React from 'react';
import classNames from 'classnames';
import Img from 'next/image';
import Link from 'next/link';
import { useNextSanityImage } from 'next-sanity-image';
import LazyLoad from 'react-lazyload';

import { sanityClient } from '@/utils/sanity/sanity.server';

import styles from './styles.module.scss';
import Icon from '@/components/common/Icon';

interface Props {
  title: string;
  description: string;
  developer: string;
  imageUrl: string;
  slug: string;
  mainImage: any;
  datapoints: [any];
}

const CaseListItem = ({
  title,
  developer,
  description,
  slug,
  mainImage,
  datapoints,
}: Props): JSX.Element => {
  const imageProps: any = useNextSanityImage(sanityClient, mainImage);
  return (
    <li className={styles.case}>
      <Link href={slug} legacyBehavior>
        <a>
          <div className={styles.container}>
            <div className={styles.image}>
              <LazyLoad once offset={500}>
                <Img
                  alt=""
                  src={imageProps?.src}
                  layout="fill"
                  objectFit="cover"
                  quality={85}
                  loading="eager"
                />
              </LazyLoad>
            </div>

            <div className={styles.overlay}>
              <div className={styles.wrapper}>
                <div className={styles.content}>
                  <div className={styles.left}>
                    <h2 className={styles.tTitle}>{title}</h2>
                    <p className={styles.tDeveloper}>{developer}</p>
                  </div>
                  <div className={styles.right}>
                    <p className={classNames(styles.tDescription, styles.fadeIn)}>{description}</p>
                    <dl className={classNames(styles.datapoints, styles.fadeIn)}>
                      {datapoints &&
                        datapoints.map((d, i) => (
                          <div key={i}>
                            <dt>{d.number}</dt>
                            <dd>{d.caption}</dd>
                          </div>
                        ))}
                    </dl>
                  </div>
                </div>
                <div className={classNames(styles.tLink, styles.fadeIn)}>
                  <div className={styles.tLink}>
                    <span>see case</span>
                    <Icon type="arrowright" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </Link>
    </li>
  );
};

export default CaseListItem;
