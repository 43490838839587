import image3D from './images/3d.jpg';
import imageAfterSales from './images/after-sales.jpg';
import imageAnalytics from './images/analytics.jpg';
import imageAnalysis from './images/analysis.jpg';
import imageDataStorage from './images/data-storage.jpg';
import imageLeads from './images/leads.jpg';
import imagePresentation from './images/presentation.jpg';
import imageProjectConfigurator from './images/project-configurator.jpg';
import imageSalesMarketing from './images/sales-marketing.jpg';
import imageServices from './images/services.jpg';

export const list = [
  {
    image: imageAnalysis.src,
    label: 'M360 products',
    title: 'Analysis',
    description:
      'Leverage data and machine learning to make informed decisions on what to build – based on true willingness to pay.',
    links: [
      {
        slug: '/solutions/plot-ai',
        title: 'Plot.ai',
      },
    ],
  },
  {
    image: imageProjectConfigurator.src,
    label: 'M360 core',
    title: 'Project configurator',
    description:
      'Create a project, build a property picker, update leads, launch campaigns and change pictures on your webpage – all from M360.',
    links: [
      {
        slug: '/solutions/m360',
        title: 'Read more about our platform',
      },
    ],
  },
  {
    image: imageDataStorage.src,
    label: 'M360 core',
    title: 'Data storage',
    description:
      'You own and collect data across the entire marketing and sales funnel, enabling you to make data-driven decisions and optimizations within – and across – projects.',
    links: [
      {
        slug: '/solutions/m360',
        title: 'Read more about our platform',
      },
    ],
  },
  {
    image: imageLeads.src,
    label: 'M360 core',
    title: 'Leads management',
    description:
      'Handle potential buyers with professionalism and courtesy in our centralized and user-friendly lead management system.',
    links: [
      {
        slug: '/solutions/m360',
        title: 'Read more about our platform',
      },
    ],
  },
  {
    image: imageAnalytics.src,
    label: 'M360 core',
    title: 'Analytics',
    description:
      'Dive into the data collected throughout the customer journey, and identify insights and context across projects, sales stages, buildings and units.',
    links: [
      {
        slug: '/solutions/m360',
        title: 'Read more about our platform',
      },
    ],
  },
  {
    image: imageAfterSales.src,
    label: 'M360 products',
    title: 'After sales',
    description: 'Transform the after sales process from a tedious task to a valuable advantage.',
    links: [
      {
        slug: '/products/communications-and-documentation',
        title: 'Communication & documentation',
      },
      {
        slug: '/products/unit-specifications-and-customizations',
        title: 'Unit specification & customization',
      },
      {
        slug: '/products/warranty-handling',
        title: 'Warranty handling',
      },
    ],
  },
  {
    image: imagePresentation.src,
    label: 'M360 products',
    title: 'Presentation',
    description: 'Present the property allowing for the best possible user experience.',
    links: [
      {
        slug: '/products/property-explorer',
        title: 'Property explorer',
      },
      {
        slug: '/products/digital-showroom',
        title: 'Digital Showroom',
      },
      {
        slug: '/products/destination-sites',
        title: 'Newbuilds portal',
      },
    ],
  },
  {
    image: image3D.src,
    label: 'M360 products',
    title: '3D visualizations',
    description:
      'Through photorealistic 3D you’ll be able to visualize the newbuild project in ways that gives it realistic justice. Our unique proprietary data provides a competitive edge, creating 3D made specifically to engage your target groups.',
    links: [
      {
        slug: '/products/3d-images',
        title: 'Exterior 3D',
      },
      {
        slug: '/products/3d-images',
        title: 'Interior 3D',
      },
      {
        slug: '/products/animation-film',
        title: 'Animated films',
      },
      {
        slug: '/products/unreal',
        title: 'Unreal Technology',
      },
    ],
  },
  {
    image: imageSalesMarketing.src,
    label: 'M360 products',
    title: 'Sales & marketing',
    description:
      'Reach out to all relevant home buyers and convert them, by applying automated targeted marketing and digital tools.',
    links: [
      {
        slug: '/products/targeted-marketing',
        title: 'Targeted marketing',
      },
      {
        slug: '/products/project-news',
        title: 'Project news',
      },
      {
        slug: '/products/digital-checkout',
        title: 'Digital checkout',
      },
    ],
  },
  {
    image: imageServices.src,
    title: 'Services',
    description:
      'Buying a new home is an emotional act. We have three in-house services that help you reach consumers on a personal level.',
    links: [
      {
        slug: '/services/partner-agencies',
        title: 'Partner agencies',
      },
      {
        slug: '/services/newbuild-advisory',
        title: 'Newbuild advisory',
      },
      {
        slug: '/services/tech-consulting',
        title: 'Tech consulting',
      },
    ],
  },
];
