import Link from 'next/link';
import React from 'react';

import OutlineButton from '@/components/buttons/Outline';
import Banner from '@/components/common/Banner';

import styles from './styles.module.scss';

interface Props {
  label: string;
  heading: string;
  lead: string;
  links: [any];
}

const LinkToPageBanner = ({ label, heading, lead, links }: Props): JSX.Element => {
  return (
    <Banner label={label}>
      <div className={styles.content}>
        <div className={styles.left}>
          <h2>{heading}</h2>
        </div>
        <div className={styles.right}>
          {lead && <p className={styles.tLead}>{lead}</p>}
          <div className={styles.links}>
            {links.map((link) => {
              return (
                <div key={link._key} className={styles.button}>
                  <OutlineButton>
                    <Link href={link.slug} legacyBehavior>
                      <a>{link.title}</a>
                    </Link>
                  </OutlineButton>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Banner>
  );
};

export default React.memo(LinkToPageBanner);
