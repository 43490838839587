import React from 'react';

import styles from './index.module.scss';

// eslint-disable-next-line react/display-name
const plug = (Component: JSX.Element | null | any) => (
  props?: Record<string, unknown>
): JSX.Element => (
  <div className={`${styles.root}`}>
    <Component {...props} />
  </div>
);

export default plug;
