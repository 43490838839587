import React from 'react';
import { useEffect } from 'react';
import HubspotForm from 'react-hubspot-form';

declare global {
  interface Window {
    hbspt: any;
  }
}

interface Props {
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const ContactForm = ({ style, theme = 'black' }: Props): JSX.Element => {
  useEffect(() => {
    const completeCallback = (event): void => {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormSubmit' &&
        event.data.id == '4e7846df-02d2-41ad-ad8a-178f43987142'
      ) {
        gtag('event', 'contact_form_submission');
      }
    };

    window.addEventListener('message', completeCallback);

    return (): void => {
      window.removeEventListener('message', completeCallback);
    };
  }, []);

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      <div className="w-full max-w-[600px]">
        <HubspotForm
          region="na1"
          portalId="19802956"
          formId="4e7846df-02d2-41ad-ad8a-178f43987142"
          onSubmit={() => console.log('submit')}
          onReady={(form: any) => console.log('Form ready!', form)}
        />
      </div>
    </div>
  );
};

export default ContactForm;
