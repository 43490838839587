import Badge from '@/components/common/Badge';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

interface Props {
  list: any;
  limit?: number;
  theme?: string;
  style?: {
    paddingTop?: boolean;
    paddingBottom?: boolean;
  };
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const LatestNews = ({
  list = [],
  limit = null,
  style = {
    paddingTop: true,
    paddingBottom: true,
  },
  theme = 'black',
}: Props): JSX.Element => {
  if (list?.length < 1) return null;

  if (limit) {
    list.length = limit;
  }

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-10 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-20 gap-y-20 max-w-[1440px]">
        {list.map((article, index) => {
          return (
            <Link key={index + article.title} href={'/' + article.slug.current} passHref legacyBehavior>
              <a className="flex flex-col gap-5">
                {article.imageUrl && (
                  <div className="aspect-video relative">
                    <Image
                      src={article.imageUrl + '?w=800&h=450&crop=focalpoint&fit=crop'}
                      alt={article.title}
                      layout="fill"
                      className="object-cover rounded-md"
                    />
                  </div>
                )}
                {article.categories?.length > 0 && (
                  <div className="flex gap-2 flex-wrap">
                    {article.categories.map((cat) => (
                      <Badge key={cat.title} theme={theme == 'black' ? 'white' : 'black'}>
                        {cat.title}
                      </Badge>
                    ))}
                  </div>
                )}
                {article.title && <h5>{article.title}</h5>}
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default LatestNews;
