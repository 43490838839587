export const query = /* groq */ `
    "jobs": *[_type=='job'] | order(dateTime(_createdAt) desc) {
      ...,
      'slug': slug.current,
      department->{
        title,
        'slug': slug.current
      }
    },
`;

export default query.replace(/ +/g, '');
