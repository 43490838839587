import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import React from 'react';

import { useTheme } from '@/components/context/theme';
import serializers from '@/components/utils/serializers';

import styles from './styles.module.scss';

const PageBlockContent = ({ text, className = null }: any) => {
  const theme = useTheme();

  return (
    <div className={classNames(styles.portableText, theme == 'light' && styles.light, className)}>
      <BlockContent blocks={text} serializers={serializers} renderContainerOnSingleChild={true} />
    </div>
  );
};

export default React.memo(PageBlockContent);
