import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Button from '@/components/common/Button';

interface Props {
  label?: string;
  heading: string;
  text?: any;
  ctas?: any;
  theme?: string;
  align?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
  children?: any;
}

const SectionHeader = ({
  label,
  heading,
  align,
  text,
  ctas,
  theme,
  style,
  children,
}: Props): JSX.Element => {
  const themeClass = (theme: string): string => {
    switch (theme) {
      case 'white':
        return 'bg-white text-black';
      case 'green':
        return 'bg-green text-white';
      default:
        return 'bg-black text-white';
    }
  };

  const alignClass = (align: string): string => {
    switch (align) {
      case 'left':
        return 'justify-start text-left';
      case 'right':
        return 'justify-end text-left';
      default:
        return 'justify-center text-center';
    }
  };

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      <div className={`max-w-[1440px] w-full flex ${alignClass(align)}`}>
        <div className={`flex flex-col gap-5 md:gap-10 max-w-[800px]`}>
          {label && <p className="text-lg">{label}</p>}
          {heading && <h2>{heading}</h2>}
          {text && (typeof text == 'string' ? text : <BlockContent blocks={text} />)}
          {ctas?.length > 0 && (
            <div className="flex flex-row flex-wrap justify-center gap-8">
              {ctas.map((cta) => (
                <Button key={cta._key} {...cta} />
              ))}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SectionHeader);
