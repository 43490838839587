import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import Button from '@/components/common/Button';
import serializers from '@/components/bits/SimpleBlockContent/Serializer';
import titleSerializer from '@/components/utils/serializers/title';

interface Props {
  theme: string;
  layout: string;
  video: any;
  heading: string;
  text: any;
  ctas: any;
  style: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const ImageTextBanner = ({
  theme,
  layout,
  video,
  heading,
  text,
  ctas,
  style,
}: Props): JSX.Element => {
  const layoutClass = layout == 'right' ? 'md:flex-row-reverse' : 'md:flex-row';

  if (!video) return null;

  return (
    <div
      className={`${themeClass(theme)} flex justify-center py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
        } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
        <div className={`w-full max-w-[1440px] flex flex-col gap-10 md:gap-20 ${layoutClass}`}>
          {video && (
            <div className="flex flex-1">
              <video
                autoPlay={true}
                muted={true}
                preload="true"
                loop={true}
                className="aspect-square w-full object-cover rounded-lg"
                src={video.asset.url}
              />
            </div>
          )}
          <div className="relative gap-7 md:gap-10 flex flex-1 justify-center flex-col">
            {heading && (
              <h2 className="max-w-[600px]">
                <BlockContent
                  className="gap-5 flex flex-col"
                  blocks={heading}
                  serializers={titleSerializer}
                />
              </h2>
            )}
            {text && (
              <div className="max-w-[500px]">
                <BlockContent
                  className="gap-5 flex flex-col"
                  blocks={text}
                  serializers={serializers}
                />
              </div>
            )}
            {ctas?.length > 0 && (
              <div className="flex flex-row flex-wrap gap-8">
                {ctas.map((cta) => (
                  <Button key={cta._key} {...cta} />
                ))}
              </div>
            )}
          </div>
      </div>
    </div>
  );
};

export default React.memo(ImageTextBanner);
