import Tab from '@/components/common/Tab';
import { urlFor } from '@/components/utils';
import React, { useState } from 'react';

const ALL_TITLE = 'All';

interface Employee {
  departments: any;
  priority: number;
  name: string;
  email: string;
  role: string;
  phone: string;
  image: any;
}

interface Props {
  departmentFilters?: any;
  showFilter?: boolean;
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
  employees: Employee[];
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const EmployeeList = ({
  departmentFilters,
  showFilter = true,
  theme = 'black',
  style,
  employees,
}: Props): JSX.Element => {
  const [departmentIndex, setDepartmentIndex] = useState(0);

  const filters = departmentFilters?.map(({ title }) => {
    return { title: title, employees: [] };
  });

  employees.forEach((e) => {
    e.departments?.forEach((d) => {
      filters.forEach((f) => {
        if (d !== null && f.title === d.title) {
          f.employees.push(e);
        }
      });
    });
  });

  let tabs = [{ employees: employees, title: ALL_TITLE }];

  tabs = [...tabs, ...filters];

  const handleChangeCategory = (i: number | null): void => {
    if (i !== null) {
      setDepartmentIndex(i);
    }
  };

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-10 md:gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      {showFilter && tabs && (
        <div className="flex gap-3 flex-wrap justify-center">
          {tabs.map((tab, index) => (
            <Tab
              key={index + tab.title}
              theme="dark"
              selected={departmentIndex == index}
              onClick={() => handleChangeCategory(index)}>
              {tab.title}
            </Tab>
          ))}
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-20 gap-y-20 w-full max-w-[1440px]">
        {tabs[departmentIndex].employees
          ?.sort((a, b) => b.priority - a.priority)
          .map((employee, index) => {
            const imageUrl = urlFor(employee.image).width(1000).height(1000).url();

            return (
              <div key={index + employee.name} className="flex flex-col gap-5">
                {employee.image?.asset?.url && (
                  <div className="aspect-square w-full relative">
                    <img src={imageUrl} alt={employee.name} className="rounded-md object-cover" />
                  </div>
                )}
                <div className="flex flex-col">
                  {employee.name && <h5>{employee.name}</h5>}
                  {employee.role && <p className="text-sm opacity-50">{employee.role}</p>}
                </div>
                <div className="flex flex-col">
                  {employee.phone && (
                    <p className="text-sm">
                      <a href={`tel:${employee.phone}`}>{employee.phone}</a>
                    </p>
                  )}
                  {employee.email && (
                    <p className="text-sm">
                      <a href={`mailto:${employee.email}`}>{employee.email}</a>
                    </p>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EmployeeList;
