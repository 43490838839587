import React from 'react';
import { HubspotMeeting } from '@/components/common/HubsportMeeting';
import { useEffect } from 'react';

declare global {
  interface Window {
    hbspt: any;
  }
}

interface Props {
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
  formUrl?: string;
}

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const DEFAULT_URL = 'https://meetings.hubspot.com/alexander-woeien/m360-demo?embed=true';

const ContactForm = ({ style, theme = 'black', formUrl }: Props): JSX.Element => {
  useEffect(() => {
    const completeCallback = (event): void => {
      if (
        event.data.meetingBookSucceeded !== undefined &&
        event.data.meetingBookSucceeded === true
      ) {
        gtag('event', 'book_demo_submission');
      }
    };

    window.addEventListener('message', completeCallback);

    return (): void => {
      window.removeEventListener('message', completeCallback);
    };
  }, []);

  const url = formUrl ?? DEFAULT_URL;

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      <div className="w-full max-w-[1440px]">
        <HubspotMeeting src={url} className="" id="myID" />
      </div>
    </div>
  );
};

export default ContactForm;
