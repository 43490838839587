import classnames from 'classnames';
import React from 'react';

import { useTheme } from '@/components/context/theme';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  labelClassName?: string;
  label: string;
  children: React.ReactNode;
  hideLabel?: boolean;
}

const Banner = ({ className, labelClassName, label, children, hideLabel }: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <div className={classnames(styles.banner, className)}>
      {!hideLabel && (
        <div
          className={classnames(
            styles.label,
            theme === 'light' && styles.themeLight,
            labelClassName
          )}>
          <p className={styles.labelText}>{label}</p>
        </div>
      )}
      {children}
    </div>
  );
};

export default Banner;
