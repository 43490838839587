import React from "react";

const themeClass = (theme: string): string => {
    switch (theme) {
        case 'black':
            return 'bg-[#222] text-white';
        case 'green':
            return 'bg-primary text-black';
        default:
            return 'bg-white text-[#222]';
    }
};

const Badge = ({ theme, children }: {
    children: React.ReactNode;
    theme: 'black' | 'white' | 'green';
}): JSX.Element => {

    return (
        <span className={`${themeClass(theme)} flex gap-2 rounded-md font-bold tracking-widest text-xxs uppercase px-2 py-1`}>{children}</span>
    );

}

export default Badge;