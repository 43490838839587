import classNames from 'classnames';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './styles.module.scss';

function recycle(items): Array<JSX.Element> {
  if (!items || !items.length) {
    return [];
  }

  const values = [];
  for (let i = 0; i < 50;) {
    for (let j = 0; j < items.length; j++) {
      values.push(items[j]);
      i++;
    }
  }

  return values;
}

function elements(values): JSX.Element {
  if (!values || !values.length) return null;
  return (
    <>
      {values.map((item, i) => (
        <li key={i}>
          <img src={item.imageUrl} alt="alt" />
        </li>
      ))}
    </>
  );
}

interface Props {
  items: [];
  className?: string;
}

const Marquee = ({ items, className }: Props): JSX.Element => {
  if (!items || !items.length) return null;
  return (
    <div className={classNames(styles.marquee, className)}>
      <div className={styles.container}>
        <Row>{elements(recycle(items))}</Row>
        <Row>{elements(recycle(items).reverse())}</Row>
        <Row>{elements(recycle(items))}</Row>
        <Row>{elements(recycle(items).reverse())}</Row>
      </div>
    </div>
  );
};

const Row = ({ children }: {
  children: React.ReactNode
}): JSX.Element => {
  const { ref, inView } = useInView({ threshold: 0 });

  return (
    <div
      ref={ref}
      className={styles.line}
      style={{ animationPlayState: inView ? 'running' : 'paused' }}>
      <ul className={styles.content}>{children}</ul>
    </div>
  );
};

export default React.memo(Marquee);
