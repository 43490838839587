import Badge from '@/components/common/Badge';
import React, { useState } from 'react';
import Tab from '@/components/common/Tab';
import Image from 'next/image';
import Button from '@/components/common/Button';
import Link from 'next/link';

const ALL_PAGES_TITLE = 'All';

interface Props {
  categoryFilters?: any;
  selectedPages?: any;
  allPages: any;
  showFilter?: boolean;
  heading?: string;
  text?: any;
  limit?: number;
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
}
const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const PageList = ({
  categoryFilters = [],
  selectedPages = [],
  allPages = [],
  showFilter = false,
  limit = null,
  style = null,
  theme = 'black',
}: Props): JSX.Element => {
  const [pageCategoryIndex, setPageCategoryIndex] = useState(0);

  if ((!allPages || allPages.length < 1) && selectedPages?.length < 0) return null;

  let allFilters = allPages.reduce(
    (arr, curr) =>
      curr.categories ? [...arr, ...curr.categories.map((cat) => ({ title: cat.title }))] : arr,
    []
  );

  allFilters = Array.from(
    new Set(allFilters.map((obj) => JSON.stringify(obj)))
  ).map((string: string) => JSON.parse(string));

  const categories = ((categoryFilters?.length > 0 ? categoryFilters : null) || allFilters).map(
    ({ title }) => ({
      title,
      pages: allPages.filter((page) =>
        page.categories?.some((category) => category.title === title)
      ),
    })
  );

  let tabs = [
    {
      title: ALL_PAGES_TITLE,
      pages: categories
        .reduce((arr, curr) => [...arr, ...curr.pages], [])
        .sort((a, b) => {
          return a.order - b.order;
        }),
    },
    ...categories,
  ];

  if (selectedPages?.length > 0) {
    tabs = [
      {
        title: ALL_PAGES_TITLE,
        pages: selectedPages,
      },
    ];
  }

  const getActivePages = (): any => {
    const arr = tabs[pageCategoryIndex].pages;
    return limit ? arr.splice(0, limit) : arr;
  };

  return (
    <div
      className={`${themeClass(
        theme
      )} relative gap-10 md:gap-20 flex justify-center items-center flex-col py-8 ${
        style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : ''
      } ${style?.paddingTop ? 'pt-[5rem] md:pt-[10rem]' : ''} px-4 sm:px-12 lg:px-24`}>
      {showFilter && tabs && (
        <div className="flex gap-3 flex-wrap justify-center">
          {tabs.map((tab, index) => (
            <Tab
              key={index + tab.title}
              theme={theme == 'white' ? 'light' : 'dark'}
              selected={tabs[pageCategoryIndex].title == tab.title}
              onClick={() => setPageCategoryIndex(index)}>
              {tab.title}
            </Tab>
          ))}
        </div>
      )}
      <ul className="grid grid-cols-1 md:grid-cols-3 gap-x-20 gap-y-20 w-full max-w-[1440px]">
        {getActivePages().map((page, index) => {
          return (
            <li key={index + page.title}>
              <Link href={page.slug.current} passHref legacyBehavior>
                <a className="flex flex-col gap-5 rounded-md">
                  {page.image && (
                    <div className="aspect-video w-full">
                      <Image
                        src={page.image + '?fm=jpg&w=800&h=450&crop=focalpoint&fit=crop'}
                        alt={page.title}
                        layout="fill"
                        className="rounded-md w-full h-full absolute object-cover"
                      />
                    </div>
                  )}
                  {page.categories?.length > 0 && (
                    <div className="flex gap-2 flex-wrap">
                      {page.categories.map((cat) => (
                        <Badge key={cat.title} theme="green">
                          {cat.title}
                        </Badge>
                      ))}
                    </div>
                  )}
                  {page.title && <h5>{page.title}</h5>}
                  {page.description && (
                    <p>
                      {page.description.length > 100
                        ? page.description.slice(0, 100) + '...'
                        : page.description}
                    </p>
                  )}
                  {page.slug.current && (
                    <div>
                      <Button
                        theme={theme == 'white' ? 'blackOutline' : 'whiteOutline'}
                        size="large"
                        className="inline-flex"
                        noRoute
                        noButton>
                        Learn more
                      </Button>
                    </div>
                  )}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PageList;
